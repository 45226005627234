export const globalConstants = {
	FOOTER_LINK: 'https://www.peopleschoice.com.au/consumer-data-right-policy',
	VERSION_NUMBER: '1.27.0',
	READ: 'read',
	UNREAD: 'unread',
	NOTIFICATIONS: 'notifications',
	PROFILE_SCOPE: 'profile',
	JOINT: 'joint',
	NOT_ELIGIBLE: 'notEligible',
	SHARING: 'sharing',
	ALLOWED: 'allowed',
	DENIED: 'denied',
	CHANGE_REQUEST: 'changeRequest',
	PENDING_APPROVAL: 'pendingApproval',
	CHANGE_REQUEST_EXPIRED: 'changeRequestExpired',
	PENDING: 'pending',
	APPROVED: 'approved',
	DECLINED: 'declined',
	PEOPLES_CHOICE: 'https://www.peopleschoice.com.au/',
	CDR_GOV_AU: 'https://www.cdr.gov.au/',
	ACCOUNTS_LOCATION: '/accounts',
	TOO_MANY_RETRIES: 'too_many_retries',
	TOO_MANY_RESEND: 'too_many_resend',
	ALL_NOTIFICATIONS: 'all_notifications',
	FREQUENCY: 'frequency',
	INSTANTLY: 'instantly',
	MONTHLY: 'monthly',
	PARTIAL: 'partial',
	ACTIVE: 'active',
	ALLOWED_SINGLE: 'allowed_single',
	DENIED_SINGLE: 'denied_single',
	ENABLE: 'enable',
	DISABLE: 'disable',
	SELECTED_PROFILE: 'selectedProfile',
	MEMBER_ID: 'memberId',
	ADMIN: 'admin',
	STANDARD: 'standard',
	BUSINESS: 'business',
	SOLE_TRADER: 'sole_trader',
	PERSONAL: 'personal',
	SKIP_PROFILE_SCREEN: 'skipProfileScreen',
	SECONDARY: 'secondary',
	AVAILABLE_FOR_SHARING: 'available-for-sharing',
	UNAVAILABLE_FOR_SHARING: 'unavailable-for-sharing',
	CONSUMER_ACCESS_TOKEN: 'consumer_access_token',
	CONSUMER_TOKEN_TYPE: 'consumer_token_type',
	CONSUMER_EXPIRES_IN: 'consumer_expires_in',
	LOGIN_ACTION: 'login',
	RESEND_ACTION: 'resend_otp',
	LEARN_MORE_SETTINGS: 'https://www.peopleschoice.com.au/managing-my-money/open-banking',
	REVOCATION: 'revocation',
	WITHDRAWN: 'withdrawn',
	BRAND: 'brand',
	DATE_OF_BIRTH: 'dateOfBirth'
};
