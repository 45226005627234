import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { StopSharingPage, NotFoundPage } from '..';
import {
	ConsentContentPage,
	ConsentDetailsPage,
	ConsentJointAccountEnable,
	ConsentJointAccountDisable,
	ConsentBusinessAccountEnable,
	ConsentBusinessAccountDisable,
	ConsentSecondaryAccountEnable,
	ConsentSecondaryAccountDisable
} from '.';
import { globalConstants } from '../../constants';
import { BottomNavigationBar, HeaderComponent, LoaderComponent } from '../../components';
import { fetchConsents } from '../../store/slices/consent/thunks';
import { selectConsents, selectConsentsLoading } from '../../store/slices/consent/selectors';
import { selectNotifications, selectUnreadCount } from '../../store/slices/notification/selectors';
import { selectMemberId } from '../../store/slices/member/selectors';
import { selectSelectedProfile } from '../../store/slices/profile/selectors';
import { fetchNotifications } from '../../store/slices/notification/thunks';
import { changeMenuItemsStatusNumber } from '../../store/slices/consent/slice';
import { fetchRepresentatives } from '../../store/slices/settings/thunks';
import { selectRepresentativesLoading } from '../../store/slices/settings/selectors';

const ConsentsPage = ({ t }) => {
	const dispatch = useDispatch();
	const memberId = useSelector(selectMemberId);
	const consentsLoading = useSelector(selectConsentsLoading);
	const consents = useSelector(selectConsents);
	const notifications = useSelector(selectNotifications);
	const unreadCount = useSelector(selectUnreadCount);
	const selectedProfile = useSelector(selectSelectedProfile);
	const representativesLoading = useSelector(selectRepresentativesLoading);

	useEffect(() => {
		document.title = 'Consents page';
	}, []);

	useEffect(() => {
		if (memberId && selectedProfile) {
			dispatch(fetchConsents({ memberId, profileId: selectedProfile.profileId, profileType: selectedProfile.profileType }));
		}
	}, [memberId, selectedProfile, dispatch]);

	useEffect(() => {
		if (consents?.length === 0 && memberId && selectedProfile?.profileType === globalConstants.BUSINESS && !representativesLoading) {
			dispatch(fetchRepresentatives({ memberId, profileId: selectedProfile.profileId }));
		}
	}, [consents, memberId, selectedProfile, dispatch]);

	useEffect(() => {
		memberId && dispatch(fetchNotifications(memberId));
	}, [dispatch, memberId, t]);

	useEffect(() => {
		notifications?.length > 0 && dispatch(changeMenuItemsStatusNumber({ tab: globalConstants.NOTIFICATIONS, statusNumber: unreadCount }));
	}, [dispatch, notifications, unreadCount]);

	return (
		<>
			<div className="main-page">
				{consentsLoading ? (
					<>
						<HeaderComponent />
						<div className="accounts-page">
							<LoaderComponent />
						</div>
					</>
				) : (
					<Routes>
						<Route path="/" element={<ConsentContentPage />} />
						<Route path=":id/details/enable-joint-account" element={<ConsentJointAccountEnable />} />
						<Route path=":id/details/disable-joint-account" element={<ConsentJointAccountDisable />} />
						<Route path=":id/details/enable-business-account" element={<ConsentBusinessAccountEnable />} />
						<Route path=":id/details/disable-business-account" element={<ConsentBusinessAccountDisable />} />
						<Route path=":id/details/enable-secondary-account" element={<ConsentSecondaryAccountEnable />} />
						<Route path=":id/details/disable-secondary-account" element={<ConsentSecondaryAccountDisable />} />
						<Route path=":id/details" element={<ConsentDetailsPage />} />
						<Route path=":id/stop-sharing" element={<StopSharingPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				)}
			</div>
			<BottomNavigationBar />
		</>
	);
};

export default withTranslation()(ConsentsPage);
