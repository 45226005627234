import React from 'react';
import { withTranslation } from 'react-i18next';
import { globalConstants } from '../constants';

const FooterComponent = ({ t }) => (
	<div className="footer-component">
		<span>
			{t('label.your_member_number_will_not_be_shared')} {t('label.one_time_pass_are_used')} {t('label.you_will_never_be_asked')}{' '}
			{t('label.for_more_information')}
			<a
				target="_blank"
				aria-label={`${t('label.please_visit_cdr_pccu')} ${globalConstants.FOOTER_LINK}`}
				href={globalConstants.FOOTER_LINK}
				rel="noopener noreferrer">
				{globalConstants.FOOTER_LINK}
			</a>
		</span>
	</div>
);

export default withTranslation()(FooterComponent);
