import React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
	MemberPage,
	OTPPage,
	NotFoundPage,
	ErrorSessionTimedOutPage,
	SettingsPage,
	AccountsPage,
	ConsentsPage,
	NotificationsPage,
	ProfilesPage
} from './pages';
import { ErrorComponent } from './components';
import { useErrorBoundary } from 'use-error-boundary';
import { ToastContainer } from 'react-toastify';
import { sendError } from './store/slices/error/thunks';

const App = () => {
	const dispatch = useDispatch();
	const { ErrorBoundary } = useErrorBoundary({
		onDidCatch: (error, errorInfo) => {
			dispatch(sendError({ message: error.message, stack: errorInfo.componentStack }));
		}
	});

	return (
		<ErrorBoundary renderError={({ err }) => <ErrorComponent error={err} />}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<MemberPage />} />
					<Route path="/otp" element={<OTPPage />} />
					<Route path="/profile-selection" element={<ProfilesPage />} />
					<Route path="/consents/*" element={<ConsentsPage />} />
					<Route path="/accounts/*" element={<AccountsPage />} />
					<Route path="/notifications/*" element={<NotificationsPage />} />
					<Route path="/settings/*" element={<SettingsPage />} />
					<Route path="/blank">Blank page</Route>
					<Route path="/session-timed-out" element={<ErrorSessionTimedOutPage />} />
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
			<ToastContainer />
		</ErrorBoundary>
	);
};

export default App;
