import { createSelector } from '@reduxjs/toolkit';

const selectMemberData = (state) => state.member;

// fetchMemberDetails
export const selectMemberId = createSelector(selectMemberData, (t) => t?.memberId);
export const selectMemberLoading = createSelector(selectMemberData, (t) => t?.fetchMemberDetailsStatus === 'loading');
export const selectMaskedPhoneNumber = createSelector(selectMemberData, (t) => t?.maskedPhoneNumber);
export const selectMemberErrorMessage = createSelector(selectMemberData, (t) => t?.error);
export const selectExpiresAt = createSelector(selectMemberData, (t) => t?.expiresAt);
export const selectCodeCreated = createSelector(selectMemberData, (t) => t?.codeCreated);

// authenticate
export const selectAuthenticateLoading = createSelector(selectMemberData, (t) => t?.authenticateStatus === 'loading');
export const selectAuthenticationErrorMessage = createSelector(selectMemberData, (t) => t?.authenticationErrorMessage);
export const selectAuthenticationExpiresAt = createSelector(selectMemberData, (t) => t?.authenticationExpiresAt);

// resendOtpCode
export const selectResendingOtpCode = createSelector(selectMemberData, (t) => t?.resendOtpCodeStatus === 'loading');
export const selectDateOfBirth = createSelector(selectMemberData, (t) => t?.dateOfBirth);
