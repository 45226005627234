import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { memberReducer, profileReducer, consentReducer, notificationReducer, settingsReducer, accountReducer, errorReducer } from './slices';
import axios from 'axios';
import { changeRoute, getAccessToken, getItem, removeItem, setItem } from '../helpers';
import { config, globalConstants } from '../constants';
import { sendError } from './slices/error/thunks';
import { fetchMemberDetails } from './slices/member/thunks';
import { selectProfile } from './slices/profile/slice';
import { fetchProfiles } from './slices/profile/thunks';

axios.defaults.baseURL = config.URL;

axios.interceptors.request.use(
	async (config) => {
		const response = await getAccessToken();

		if (response.OK) {
			config.headers = {
				...config.headers,
				authorization: `${response.data.tokenType} ${response.data.accessToken}`
			};
		} else if (response.message === 'Session expired') {
			changeRoute('/session-timed-out');
		}

		const brand = getItem(globalConstants.BRAND);
		if (brand) {
			config.headers = {
				...config.headers,
				'x-brand': brand
			};
		}

		return config;
	},
	(error) => Promise.reject(error)
);

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		try {
			error.response.data.errorMessage === 'profile_not_found' && changeRoute('/profile-selection');
		} catch (error) {
			sendError('store - axios.interceptors.response - ', error);
		}
		return Promise.reject(error);
	}
);

const coreMiddleware = (store) => (next) => (action) => {
	if (fetchMemberDetails.pending.match(action)) {
		removeItem(globalConstants.MEMBER_ID);
		removeItem(globalConstants.DATE_OF_BIRTH);
		removeItem(globalConstants.CONSUMER_ACCESS_TOKEN);
		removeItem(globalConstants.CONSUMER_TOKEN_TYPE);
		removeItem(globalConstants.CONSUMER_EXPIRES_IN);
	} else if (fetchMemberDetails.fulfilled.match(action)) {
		setItem(globalConstants.MEMBER_ID, action.payload.memberId);
		setItem(globalConstants.DATE_OF_BIRTH, action.meta.arg.dateOfBirth);
	} else if (selectProfile.match(action)) {
		if (action.payload) {
			setItem(globalConstants.SELECTED_PROFILE, action.payload);
		} else {
			removeItem(globalConstants.SELECTED_PROFILE);
			removeItem(globalConstants.SKIP_PROFILE_SCREEN);
		}
	} else if (fetchProfiles.pending.match(action)) {
		removeItem(globalConstants.SELECTED_PROFILE);
		removeItem(globalConstants.SKIP_PROFILE_SCREEN);
	} else if (fetchProfiles.fulfilled.match(action)) {
		const businessProfiles = action.payload.filter(
			(p) => p.profileType === globalConstants.BUSINESS || p.profileType === globalConstants.SOLE_TRADER
		);
		const personalProfiles = action.payload.filter((p) => p.profileType === globalConstants.PERSONAL);
		let selectedProfile;
		if (businessProfiles?.length === 0 && personalProfiles?.length === 1) {
			selectedProfile = personalProfiles[0];
			setItem(globalConstants.SELECTED_PROFILE, selectedProfile);
			setItem(globalConstants.SKIP_PROFILE_SCREEN, true);
		} else if (businessProfiles?.length === 1 && personalProfiles?.length === 0) {
			selectedProfile = businessProfiles[0];
			setItem(globalConstants.SELECTED_PROFILE, selectedProfile);
			setItem(globalConstants.SKIP_PROFILE_SCREEN, true);
		}
	}

	return next(action);
};

export const store = configureStore({
	reducer: {
		member: memberReducer,
		profile: profileReducer,
		consent: consentReducer,
		notification: notificationReducer,
		settings: settingsReducer,
		account: accountReducer,
		error: errorReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}).concat(coreMiddleware)
});

setupListeners(store.dispatch);
