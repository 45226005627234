import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { HeaderComponent, ConsentComponent, SwitchComponent } from '../../components';
import { Button } from '../../basiqComponents';
import Modal from 'react-modal';
import classnames from 'classnames';
import { globalConstants } from '../../constants';
import moment from 'moment-timezone';
import { selectConfirmationFileLoading, selectConsents, selectWithdrawalFileLoading } from '../../store/slices/consent/selectors';
import { selectMemberId } from '../../store/slices/member/selectors';
import {
	getConfirmationPdfFile,
	getHistoryPdfFile,
	getWithdrawalPdfFile,
	setAccountPermissionForIndividualConsent
} from '../../store/slices/consent/thunks';

const ConsentDetailsPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const consents = useSelector(selectConsents);
	const memberId = useSelector(selectMemberId);
	const confirmationFileLoading = useSelector(selectConfirmationFileLoading);
	const withdrawalFileLoading = useSelector(selectWithdrawalFileLoading);
	const [stopSharing, setStopSharing] = useState(false);
	const [jointAccountModal, setJointAccountModal] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState();
	const consentId = useParams().id;
	const consent = consents?.find((consent) => consent.consentId === consentId);
	const sortedConsentHistory = consent?.history?.slice().sort((a, b) => b.createdAt - a.createdAt);

	const popUpClassName = classnames('consent-details-page__pop-up', (stopSharing || jointAccountModal) && 'consent-details-page__pop-up--visible');
	const popUpWrapperClassName = classnames(
		'consent-details-page__pop-up-wrapper',
		(stopSharing || jointAccountModal) && 'consent-details-page__pop-up-wrapper--visible'
	);

	const statusClassName = classnames('consent-details-page__header-status-value', {
		'consent-details-page__header-status--cancelled': consent && consent.status !== globalConstants.ACTIVE
	});

	useEffect(() => {
		document.title = 'Consents page - details';
	}, []);

	const handleDisableClick = (accountId, accountType) =>
		dispatch(
			setAccountPermissionForIndividualConsent({
				consentId,
				memberId,
				accountId,
				sharingPermission: globalConstants.DENIED,
				accountType,
				status: globalConstants.DISABLE
			})
		).then((status) => !status?.error && navigate(`/consents/${consentId}/details/disable-${accountType}-account`));

	const handleEnableClick = (accountId, accountType) =>
		dispatch(
			setAccountPermissionForIndividualConsent({
				consentId,
				memberId,
				accountId,
				sharingPermission: globalConstants.ALLOWED,
				accountType,
				status: globalConstants.ENABLE
			})
		).then((status) => !status?.error && navigate(`/consents/${consentId}/details/enable-${accountType}-account`));

	const getStopSharingButton = (id) =>
		consent.status === globalConstants.ACTIVE &&
		consent.isOwner &&
		consent.manageConsent && (
			<div className="consent-details-page__button-wrapper">
				<Button
					id={id}
					type="submit"
					text={t('label.stop_sharing')}
					theme="secondary-dark"
					spanClassName="primary-text"
					onClick={() => setStopSharing(true)}
				/>
			</div>
		);

	return (
		<>
			<HeaderComponent onBack={() => navigate('/consents')} />
			<div className="consent-details-page">
				{consent && (
					<>
						<div className="consent-details-page__header">
							<img alt={consent.orgName} src={consent.orgLogoUri} />
							<div className="consent-details-page__header-name-wrapper">
								<div className="consent-details-page__header-name">{consent.orgName}</div>
								<div className="consent-details-page__header-type">Budget tracker</div>
							</div>
							<div className="consent-details-page__status-wrapper">
								<div className="consent-details-page__header-status-label"> {t('label.status')}</div>
								<div className={statusClassName}>{t(consent.statusText)}</div>
							</div>
						</div>
						{getStopSharingButton('stop-sharing-top-button')}
						{consent.status !== globalConstants.ACTIVE && (
							<>
								<div className="consent-details-page__archived-auth-subtitle">
									{t('label.is_no_longer_collecting_and_using_your_data_for_this_product', {
										consentName: consent.orgName
									})}
								</div>
								<span
									className="consent-details-page__confirmation-of-consent consent-details-page__consent-withdrawal-confirmation"
									target="_blank"
									rel="noopener noreferrer"
									onClick={() =>
										!withdrawalFileLoading &&
										dispatch(getWithdrawalPdfFile({ memberId, consentId, fileName: consent.withdrawal_pdf }))
									}>
									<span className="icon-file"></span>
									<div className="consent-details-page__content-wrapper">
										<label>{t('label.consent_withdrawal')}</label>
										<label>{moment(consent.expiresAt * 1000).format('Do MMMM YYYY')}</label>
									</div>
									{withdrawalFileLoading ? (
										<span itemProp="icon" className="icon-loader-outline rotating"></span>
									) : (
										<span className="icon-download-outline"></span>
									)}
								</span>
							</>
						)}
						<span
							className="consent-details-page__confirmation-of-consent"
							target="_blank"
							rel="noopener noreferrer"
							onClick={() =>
								!confirmationFileLoading &&
								dispatch(getConfirmationPdfFile({ memberId, consentId, fileName: consent.confirmation_pdf }))
							}>
							<span className="icon-file"></span>
							<div className="consent-details-page__content-wrapper">
								<label>{consent.amended ? t('label.amendment_of_consent') : t('label.confirmation_of_consent')}</label>
								<label>{moment((consent.amended ? consent.amendedAt : consent.createdAt) * 1000).format('Do MMMM YYYY')}</label>
								{consent.amended && (
									<>
										{consent.amendmentChanges.includes("accountsShared") && (
											<label>{t('label.accounts_shared')}</label>
										)}
										{consent.amendmentChanges.includes("sharingPeriod") && <label>{t('label.sharing_period_changed')}</label>}
										{consent.amendmentChanges.includes("informationShared") && <label>{t('label.information_shared')}</label>}
									</>
								)}
							</div>
							{confirmationFileLoading ? (
								<span itemProp="icon" className="icon-loader-outline rotating"></span>
							) : (
								<span itemProp="icon" className="icon-download-outline"></span>
							)}
						</span>
						{sortedConsentHistory?.map((item, itemIndex) => {
							return (
								<span
									key={itemIndex}
									className="consent-details-page__confirmation-of-consent"
									target="_blank"
									rel="noopener noreferrer"
									onClick={() =>
										!item.fileLoading &&
										dispatch(
											getHistoryPdfFile({
												memberId,
												consentId,
												versionId: item.versionId,
												fileName: consent.confirmation_pdf
											})
										)
									}>
									<span className="icon-file"></span>
									<div className="consent-details-page__content-wrapper">
										<label>{item.amended ? `${t('label.amendment_of_consent')}` : t('label.confirmation_of_consent')}</label>
										<label>{moment(item.createdAt * 1000).format('Do MMMM YYYY')}</label>
										{item.amended &&  (
											<>
											{item.amendmentChanges.includes("accountsShared") && <label>{t('label.accounts_shared')}</label>}
											{item.amendmentChanges.includes("sharingPeriod") && <label>{t('label.sharing_period_changed')}</label>}
											{item.amendmentChanges.includes("informationShared") && <label>{t('label.information_shared')}</label>}
										</>
										)}
									</div>
									{item.fileLoading ? (
										<span itemProp="icon" className="icon-loader-outline rotating"></span>
									) : (
										<span itemProp="icon" className="icon-download-outline"></span>
									)}
								</span>
							);
						})}
						<div className="consent-details-page__sharing-conditions">
							{consent?.accounts?.length > 0 && (
								<>
									<div className="consent-details-page__sharing-condition">
										<span className="icon-inbox"></span>
										<span className="consent-details-page__sharing-conditions-title">{t('label.accounts')}</span>
									</div>
									{consent.accounts.map((account, index) => {
										const {
											displayName,
											isDisabled,
											showToggle,
											availableForSharing,
											loading,
											accountId,
											accountType,
											isJointAccount,
											maskedNumber
										} = account;
										return (
											<div key={index} className="consent-details-page__sharing-conditions-details">
												<div className="consent-details-page__sharing-condition-item  consent-details-page__account-item-wrapper">
													<div className="consent-details-page__sharing-condition-item-title consent-details-page__account-wrapper">
														<span>{displayName}</span>
														{consent.status === globalConstants.ACTIVE && (
															<>
																{isDisabled ? (
																	<div className="consent-details-page__disabled-switch">{t('label.disabled')}</div>
																) : (
																	showToggle && (
																		<SwitchComponent
																			checked={availableForSharing}
																			loading={loading}
																			onDisableClick={() => handleDisableClick(accountId, accountType)}
																			onEnableClick={() => handleEnableClick(accountId, accountType)}
																		/>
																	)
																)}
															</>
														)}
													</div>
													<div className="consent-details-page__sharing-condition-item-content">{maskedNumber}</div>
												</div>
												{isJointAccount && (
													<div className="consent-details-page__held-with-other-wrapper">
														<div className="consent-details-page__held-with-other">
															{t('label.held_with_other_account')}
														</div>
														{isDisabled && (
															<span
																onClick={() => {
																	setJointAccountModal(true);
																	setSelectedAccount(account);
																}}
																className="icon-question-mark-circle"></span>
														)}
													</div>
												)}
											</div>
										);
									})}
								</>
							)}
							<div className="consent-details-page__sharing-condition">
								<span className="icon-layers"></span>
								<span className="consent-details-page__sharing-conditions-title">{t('label.data_we_have_shared')}</span>
							</div>
							<div className="consent-details-page__sharing-conditions-details">{<ConsentComponent consent={consent} />}</div>
							<div className="consent-details-page__sharing-condition">
								<span className="icon-calendar"></span>
								<span className="consent-details-page__sharing-conditions-title">{t('label.dates_and_sharing_period')}</span>
							</div>
							<div className="consent-details-page__sharing-conditions-details consent-details-page__dates-sharing">
								<div>
									{consent.scopes?.includes('bank:transactions:read') && (
										<div className="consent-details-page__sharing-condition-item">
											<div className="consent-details-page__sharing-condition-item-title">
												{t('label.historical_data_required')}
											</div>
											<div className="consent-details-page__sharing-condition-item-content">
												{t('label.this_will_include_data')}
											</div>
										</div>
									)}
									<div className="consent-details-page__sharing-condition-item">
										<div className="consent-details-page__sharing-condition-item-title">{t('label.when_you_gave_consent')}</div>
										<div className="consent-details-page__sharing-condition-item-content">{consent.whenYouGaveConsent}</div>
									</div>
									<div className="consent-details-page__sharing-condition-item">
										<div className="consent-details-page__sharing-condition-item-title">{t('label.sharing_period')}</div>
										<div className="consent-details-page__sharing-condition-item-content">{consent.sharingPeriodDate}</div>
									</div>
									<div className="consent-details-page__sharing-condition-item">
										<div className="consent-details-page__sharing-condition-item-title">
											{t(consent.sharingConditionItemTitle)}
										</div>
										<div className="consent-details-page__sharing-condition-item-content">
											{consent.sharingConditionItemDate?.includes('label')
												? t(consent.sharingConditionItemDate)
												: consent.sharingConditionItemDate}
										</div>
									</div>
								</div>
							</div>
							<div className="consent-details-page__sharing-condition">
								<span className="icon-info"></span>
								<span className="consent-details-page__sharing-conditions-title">{t('label.additional_information')}</span>
							</div>
							<div className="consent-details-page__sharing-conditions-details">
								<div>
									<div className="consent-details-page__sharing-condition-item-additional-info-title">
										{t('label.what_is_open_banking')}
									</div>
									<div className="consent-details-page__sharing-condition-item-additional-info-content">
										<div className="consent-details-page__what-is-open-banking-content">
											<label>{t('label.open_banking_allows')}</label>
											<label>{t('label.if_you_would_like')}</label>
										</div>
									</div>
									<div className="consent-details-page__sharing-condition-item-additional-info-title">
										{t('label.how_is_your_data_handled')}
									</div>
									<div className="consent-details-page__sharing-condition-item-additional-info-content">
										<div className="consent-details-page__what-is-open-banking-content">
											<label>{t('label.you_should_check_with_ADR', { consentName: consent.orgName })}</label>
										</div>
									</div>
									<div className="consent-details-page__sharing-condition-item-additional-info-title">
										{t('label.accreditation')}
									</div>
									<div className="consent-details-page__sharing-condition-item-additional-info-content">
										{t('label.is_an_accredited_data_recipient', { consentName: consent.orgName })}.{' '}
										{t('label.you_can_verify_accreditation_at')}:{' '}
										<a
											className="consent-details-page__sharing-condition-item-link"
											aria-label={`${t('label.please_visit_cdr_gov')} https://www.cdr.gov.au/`}
											href="https://www.cdr.gov.au/"
											target="_blank"
											rel="noopener noreferrer">
											www.cdr.gov.au
										</a>
										<br />
										{t('label.for_more_information')}{' '}
										<a
											className="consent-details-page__sharing-condition-item-link"
											aria-label={`${t('label.please_visit_cdr_pccu')} ${globalConstants.FOOTER_LINK}`}
											href={globalConstants.FOOTER_LINK}
											target="_blank"
											rel="noopener noreferrer">
											{globalConstants.FOOTER_LINK}
										</a>
									</div>
								</div>
							</div>
						</div>
						{getStopSharingButton('stop-sharing-bottom-button')}
						<Modal
							ariaHideApp={false}
							className={popUpClassName}
							isOpen={stopSharing}
							onRequestClose={() => setStopSharing(false)}
							overlayClassName={popUpWrapperClassName}
							shouldCloseOnOverlayClick={false}>
							<div>
								<div className="consent-details-page__pop-up-consent-info">
									<img alt={consent?.orgName || 'org name'} src={consent?.orgLogoUri} />
									<div>{consent?.orgName}</div>
								</div>
								<div className="consent-details-page__pop-up-title">{t('label.what_you_should_know_before_stop_sharing')}</div>
								<div className="consent-details-page__pop-up-question">
									<div className="consent-details-page__pop-up-question-icon">
										<span className="icon-question-mark-circle"></span>
										<div className="consent-details-page__pop-up-question-wrapper">
											<div className="consent-details-page__pop-up-question-header">{t('label.impacts_to_your_service')}</div>
											<div className="consent-details-page__pop-up-question-content">
												{t('label.your_service_may_be_impacted', { ADR: consent?.orgName })}
											</div>
											{consent?.accounts?.some((a) => a.isJointAccount) && (
												<div className="consent-details-page__pop-up-question-content">
													{t('label.all_account_holder_will_be_notified')}
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="consent-details-page__pop-up-question">
									<div className="consent-details-page__pop-up-question-icon">
										<span className="icon-info"></span>
										<div className="consent-details-page__pop-up-question-wrapper">
											<div className="consent-details-page__pop-up-question-header">
												{t('label.what_happens_with_your_shared_data')}
											</div>
											<div className="consent-details-page__pop-up-question-content">
												{t('label.is_responsible_for_data_deleting', { ADR: consent.orgName })}
											</div>
										</div>
									</div>
								</div>
								<div className="btns-container">
									<Button
										id="stop-sharing-cancel-button"
										type="submit"
										text={t('label.cancel')}
										theme="secondary-dark"
										spanClassName="primary-text"
										onClick={() => setStopSharing(false)}
									/>
									<Button
										id="stop-sharing-continue-button"
										type="submit"
										text={t('label.continue')}
										theme="secondary-dark"
										spanClassName="primary-text"
										onClick={() => navigate(`/consents/${consent.consentId}/stop-sharing`)}
									/>
								</div>
							</div>
						</Modal>
						<Modal
							ariaHideApp={false}
							className={popUpClassName}
							isOpen={jointAccountModal}
							onRequestClose={() => setJointAccountModal(false)}
							overlayClassName={popUpWrapperClassName}
							shouldCloseOnOverlayClick={false}>
							<div className="consent-details-page__disabled-account-wrapper">
								{selectedAccount && (
									<>
										<div className="consent-details-page__popup-header-component">
											<div className="consent-details-page__icon-wrapper">
												<span className="icon-question-mark-circle"></span>
												<div>
													<div>{selectedAccount.displayName}</div>
													<div className="confirmation-header-component__content-label">{selectedAccount.maskedNumber}</div>
												</div>
											</div>
											<div className="consent-details-page__held-with-other-modal">{t('label.held_with_other_account')}</div>
										</div>
										<div className="consent-details-page__content-information">
											<div>{t('label.the_data_sharing_permission_for', { displayName: selectedAccount.displayName })}</div>
											<div>{t('label.if_you_would_like_to_change')}</div>
											<div>{t('label.go_to_accounts')}</div>
										</div>
										<div className="consent-details-page__footer-information">{t('label.you_can_change_your_data')}</div>
										<Button
											id="stop-sharing-continue-button"
											type="submit"
											text={t('label.i_understand')}
											theme="secondary-dark"
											spanClassName="primary-text"
											onClick={() => setJointAccountModal(false)}
										/>
									</>
								)}
							</div>
						</Modal>
					</>
				)}
			</div>
		</>
	);
};

export default withTranslation()(ConsentDetailsPage);
