import React from 'react';
import { withTranslation } from 'react-i18next';

const HeaderComponent = ({ t, onBack, topPageRef }) => (
	<>
		<div className="header-component__top-page" ref={topPageRef}></div>
		<div className="header-component">
			{onBack && (
				<button type="button" aria-label="back" id="back-button" className="header-component__button-wrapper" onClick={onBack}>
					<span className="icon-arrow-ios-back-outline"></span>
				</button>
			)}
			<img alt="Peoples Choice Logo" src="/main-logo-pccu.png" />
		</div>
	</>
);

export default withTranslation()(HeaderComponent);
