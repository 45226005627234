import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Input = React.memo((props) => {
	const [inputValue, setInputValue] = useState('');
	const { name, inputClassName, label, readOnly, error, svg, sprite, image, inputRef, type, onClick, containerId, ...rest } = props;
	const inputContainerClassName = classnames(`input-container`, { 'input-container--error': error }, { 'input-container--read-only': readOnly });
	const innerDivClassName = classnames('inner-div', {
		populated: (inputValue && inputValue.length > 0) || (props.value && props.value.toString().length > 0)
	});
	const inputId = `input-${name}`;

	const onChange = (e) => {
		setInputValue(e.target.value);
		props.onChange && props.onChange(e);
	};

	useEffect(() => {
		setInputValue(props.value);
	}, [props.value]);

	return (
		<div className={inputContainerClassName} id={containerId}>
			<div className="input-container__wrapper">
				<div className={innerDivClassName}>
					<input ref={inputRef} id={inputId} name={name} readOnly={readOnly} type={type} {...rest} onChange={onChange} />
					<label className="inner-div__label" htmlFor={inputId}>
						{label}
					</label>
				</div>
			</div>
		</div>
	);
});

Input.defaultProps = {
	type: 'text'
};

Input.propTypes = {
	inputClassName: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func
};

export default Input;
